.news-card {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 36px;
    margin-bottom: 36px;

    @media (min-width: @screen-md) {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        padding: 28px;
        margin-bottom: 20px;
    }
}

.news-card .ant-card-body {
    padding: 0;
}

.news-card__media {
    position: relative;
    margin-bottom: 24px;

    img,
    video {
        border-radius: 14px;
    }
}

.news-card__media {
    .news-card-locked & {
        min-height: 150px;
        overflow: hidden;
        border-radius: 14px;

        img,
        video {
            filter: blur(40px);
        }
    }
}

.news-card__blur {
    .layer(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 14px;
}

.news-card__image,
.news-card__video {
    position: relative;
    z-index: 1;
    object-fit: contain;
    backdrop-filter: blur(10px);
    vertical-align: top;
    width: 100%;
    height: 100%;
    max-height: 300px;

    @media (min-width: @screen-md) {
        max-height: 400px;
    }
    @media (min-width: @screen-lg) {
        max-height: 500px;
    }
}

.news-card__subtitle {
    display: flex;
    gap: 6px;
}

.news-card__date {
    color: @neutral-7;
}

.news-card__title {
    margin-top: 16px;
}

.news-card__tag {
    &:after {
        content: '\00B7';
    }
}

.news-card__descr {
    position: relative;

    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
    img {
        .img-responsive();
    }
}

.news-card__descr {
    .news-card-locked & {
        min-height: 150px;
    }
}

.news-card__text-blur {
    filter: blur(7px);
}

.news-card__actions {
    display: flex;
    gap: 24px;
    margin-top: 24px;

    .icon {
        font-size: 20px;
    }
}

.news-card__favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .icon-heart {
        color: @error-color;
    }
}

.news-card__cta {
    margin-top: 24px;
}
