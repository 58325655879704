// ant-steps
.ant-steps {
    font-weight: 500;
    margin-bottom: 15px;
}

// ant-steps-item-title
.ant-steps-item-title {
    @media (max-width: @screen-xs-max) {
        color: @body-background !important;
        width: 0;
        padding-right: 4px !important;
    }
    &:after {
        height: 2px;
    }
}

// ant-steps-item-title-small
.ant-steps-item-title {
    .ant-steps-small & {
        font-size: @font-size-sm;
    }
}

// ant-steps-item-title-finish
.ant-steps-item-title {
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > & {
        color: @success-color;

        &:after {
            background-color: @success-color;
        }
    }
}

// ant-steps-item-icon-small
.ant-steps-item-icon {
    .ant-steps-small & {
        font-size: @font-size-base;
        line-height: 30px;
    }
}

// ant-steps-item-icon-process
.ant-steps-item-icon {
    .ant-steps-item-process > .ant-steps-item-container > & {
        background-color: @neutral-12;
        border-color: @neutral-12;
    }
}

// ant-steps-item-icon-wait
.ant-steps-item-icon {
    .ant-steps-item-wait & {
        border-color: @neutral-4;
    }
}

// ant-steps-item-icon-finish
.ant-steps-item-icon {
    .ant-steps-item-finish & {
        background-color: @success-color;
        border-color: @success-color;

        > .ant-steps-icon {
            color: @white;
        }
    }
}

// steps-content
.steps-content {
    padding-top: 10px;
    margin-bottom: 38px;

    @media (min-width: @screen-lg) {
        padding-top: 50px;
    }
}

// steps-note
.steps-note {
    border-top: 1px solid @divider-color;
    padding-top: 20px;
    padding-bottom: 0;
}

// steps-action
.steps-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 30px;
    border-top: solid 1px @divider-color;

    @media (min-width: @screen-md) {
        flex-direction: row-reverse;
    }
    .ant-btn {
        &:not(:last-child) {
            margin-bottom: 16px;

            @media (min-width: @screen-md) {
                margin: 0;
            }
        }
    }
}

// steps-action-with-header
.steps-action-with-header {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    .steps-action {
        border-top: none;
    }
}

// steps-checkbox
.steps-checkbox {
    display: flex;
    gap: 12px;
}
