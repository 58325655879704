// ant-btn
.ant-btn {
    text-transform: uppercase;

    &:focus,
    &:hover,
    &:active {
        background: @neutral-3;
        border-color: @btn-default-border;
        color: @btn-default-color;
    }

    &[disabled],
    &[disabled]:hover {
        background: @white;
    }

    a {
        text-decoration: none;
    }
}

// ant-btn-ghost
.ant-btn {
    &.ant-btn-background-ghost {
        &:focus,
        &:hover,
        &:active {
            background: rgba(@white, 10%);
            border-color: @btn-default-ghost-border;
            color: @btn-default-ghost-color;
        }

        &[disabled],
        &[disabled]:hover {
            background: @neutral-8;
            border-color: @normal-color;
            color: @normal-color;
        }
    }
}
