// img-responsive
.img-responsive {
    .img-responsive();
}

// img-responsive-height
.img-responsive-height {
    .img-responsive-height();
}

// hide-mobile
.hide-mobile {
    @media (max-width: @screen-sm-max) {
        display: none !important;
    }
}

// hide-tablet
.hide-tablet {
    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        display: none !important;
    }
}

// hide-desktop
.hide-desktop {
    @media (min-width: @screen-lg) {
        display: none !important;
    }
}

// one-line
.one-line {
    display: inline-block;
}

// text-left
.text-left {
    text-align: left;
}

// text-center
.text-center {
    text-align: center;
}

//this CSS style can be later converted to a mixin and used globally by passing the "number of lines" parameter (Refactoring Possible)
.ellipsis-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// overflow
.overflow {
    overflow: hidden;
}
