// ant-menu-item
.ant-menu-item {
    @media (min-width: @screen-lg) {
        font-size: @font-size-base;
    }
    a {
        text-decoration: none;
        position: relative;
    }
    &:focus {
        outline: none !important;
    }
}
