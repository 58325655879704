// invest
.invest {
    background-color: @neutral-3;
    padding: 75px 0 50px;
    margin-bottom: 75px;

    @media (min-width: @screen-md) {
        padding-bottom: 20px;
    }
    @media (min-width: @screen-xl) {
        padding: 100px 0 45px;
        margin-bottom: 100px;
    }
}

// invest column
.invest__col {
    @media (min-width: @screen-md) {
        .flex-col-width(8);
    }

    @media (min-width: @screen-xl) {
        .flex-col-width(5);
    }
}

// invest column-cards
.invest__col-cards {
    @media (min-width: @screen-md) {
        .flex-col-width(6);
    }
    @media (min-width: @screen-lg) {
        .flex-col-width(4);
    }
}

// invest heading
.invest__heading {
    &:extend(h1);
    margin-bottom: 30px;
}

// invest subheading
.invest__subheading {
    margin-bottom: 40px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
        margin-bottom: 60px;
    }
    > p:last-of-type {
        margin: 0;
    }
}
