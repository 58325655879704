// ant-alert
.ant-alert {
    border-radius: 4px;
    border: none;
    font-weight: 500;
    padding: 16px;
}

.ant-alert-block {
    width: 100%;
}
