// share-picker
.share-picker {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    font-size: @font-size-base;
    justify-content: center;

    @media (min-width: @screen-md) {
        justify-content: initial;
    }
}

// share-picker button
.share-picker__btn {
    &,
    &:focus {
        background-color: @item-hover-bg;
        overflow: hidden;
        height: 56px;
        width: 30%;
        padding: 0;

        @media (min-width: @screen-md) {
            height: 56px;
            width: 56px;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background: darken(@item-hover-bg, 2%);
        border-color: @neutral-5;
    }
    &[disabled] {
        &,
        &:focus,
        &:hover,
        &:active {
            background-color: @neutral-6;
            border-color: @neutral-5;
            color: @text-color;
            opacity: 1;
        }
    }
    > .icon {
        font-size: @font-size-sm;
        line-height: 30px;
    }
}

// share-picker input
.share-picker__input {
    border: none;
    border-top: 2px solid @neutral-5;
    border-bottom: 2px solid @neutral-5;
    border-radius: 0;
    width: 40%;
    margin: 0;
    height: 56px;
    font-size: 18px;
    padding: 0 5px;

    @media (min-width: @screen-md) {
        width: 100px;
    }
    &:hover,
    &:focus,
    &.ant-input-number-focused {
        box-shadow: none;
        border-color: @neutral-5;
    }
    .ant-input-number-input {
        text-align: center;
        padding: 0;
    }
    &.ant-input-number-disabled {
        background-color: @white;
        color: @normal-color;
    }
}

.share-picker__input-error {
    color: @error-color;
    font-weight: 700;
}

// share-picker multiplier
.share-picker__multiplier {
    margin-left: 15px;
    display: none;

    @media (max-width: @screen-sm-max) {
        display: none;
    }
}

.share-picker__btn-right {
    border: 2px solid @neutral-5;
    border-left: 0;
    border-radius: 0 8px 8px 0;
}

.share-picker__btn-left {
    border: 2px solid @neutral-5;
    border-right: 0;
    border-radius: 8px 0 0 8px;
}
