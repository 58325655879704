// payment-item-details
.payment-item-details {
    list-style-type: none;
    margin: 4px 0 0;
    padding: 0;
}

// payment-item-details item
.payment-item-details__item {
    clear: left;
}
