.news__top {
    position: sticky;
    top: 110px;
    z-index: 3;
    margin: -20px 0 20px;
    text-align: center;
}

.news__btn {
    text-transform: none;
}
