html {
    width: 100%;
    height: 100%;
    font-size: 62.5%; // 1rem = 10px
}

body {
    min-width: 375px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// headings
h1,
h2,
h3 {
    font-weight: 700;
    line-height: @line-height-heading;
    margin-bottom: 20px;
}
h4,
h5,
h6 {
    font-weight: 700;
    margin-bottom: 10px;
}
h1 {
    font-size: @heading-3-size;

    @media (min-width: @screen-md) {
        font-size: @heading-2-size;
    }
    @media (min-width: @screen-lg) {
        font-size: @heading-1-size;
    }
}
h1.extra-md {
    margin-bottom: 30px;
    font-size: @heading-2-size;

    @media (min-width: @screen-md) {
        font-size: @heading-1-size;
    }
    @media (min-width: @screen-xl) {
        font-size: 48px;
    }
}
h1.extra-lg {
    margin-bottom: 30px;
    font-size: @heading-2-size;

    @media (min-width: @screen-md) {
        font-size: @heading-1-size;
    }
    @media (min-width: @screen-xl) {
        font-size: 56px;
    }
}
h2 {
    font-size: @heading-3-size;

    @media (min-width: @screen-md) {
        font-size: @heading-2-size;
    }
}
h3 {
    font-size: @heading-3-size;
}
h4 {
    font-size: @heading-4-size;
}
h5 {
    font-size: @heading-5-size;
}
h6 {
    font-size: @font-size-sm;
}

// links
a {
    transition: all 0.3s ease-in-out;

    // We don't want user to be able to select text inside buttons
    &[role='button'] {
        user-select: none;
    }
    // We don't want allow user to click on disabled buttons
    &.disabled {
        pointer-events: none;
    }
}

// tel links
a[href^='tel'] {
    &,
    &:focus,
    &:hover,
    &:active {
        // On desktop we don't want user to be able to click on telephone links
        // These links should look like regular text and do nothing
        @media (min-width: @screen-md) {
            color: inherit !important;
            text-decoration: none !important;
            cursor: default !important;
            pointer-events: none;
        }
    }
}

// paragraph
p {
    margin-bottom: 20px;
}

// lists
ul,
ol {
    margin-bottom: 20px;
}

// selection
::selection {
    color: @text-color;
}
