// payment-info
.payment-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media (min-width: @screen-md) {
        flex-direction: row;
        align-items: center;
    }
}

// payment-info image
.payment-info__img {
    width: 100%;
    margin: 8px 0 12px;
    border-radius: 14px;
    overflow: hidden;

    @media (min-width: @screen-md) {
        width: 306px;
        margin-right: 28px;
    }
}

// payment-info details
.payment-info__details {
    margin-bottom: 5px;
}

// payment-info title
.payment-info__title {
    display: block;
    margin-bottom: 8px;
    font-size: @font-size-lg;
    font-weight: 500;
    margin-top: 16px;

    @media (min-width: @screen-md) {
        margin-top: 0;
    }
}

// payment-info subtext
.payment-info__subtext {
    color: @normal-color;
    margin-bottom: 24px;
}

// payment-info subtext-warning
.payment-info__subtext-warning {
    color: @warning-7;
}
