// bullets
.bullets {
    background-color: @neutral-3;
    margin-bottom: 75px;
    text-align: center;

    @media (min-width: @screen-lg) {
        background: none;
        margin-bottom: 100px;
        text-align: left;
    }
}

// bullets container
.bullets__container {
    &:extend(.container);
}

// bullets row
.bullets__row {
    flex-direction: column;
    padding: 25px 0 10px;

    @media (min-width: @screen-md) {
        padding: 26px 0;
    }
    @media (min-width: @screen-lg) {
        flex-direction: row;
        align-items: center;
        background-color: @neutral-3;
        border-radius: @border-radius-xl;
        padding: 26px;
    }
}

// bullets column
.bullets__col {
    display: flex;
    flex-basis: 50%;
    align-items: center;
    text-align: center;
    margin-left: -1px;

    @media (min-width: @screen-md) {
        flex-basis: 25%;
    }
    &::before,
    &::after {
        content: '';
        width: 15px;
        height: 23px;
    }
    & + & {
        &::before {
            border-left: solid 1px @neutral-6;
        }
    }
}

// bullets text
.bullets__text {
    flex-grow: 1;
}
