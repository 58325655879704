// icon
.icon {
    display: inline-block;
    font: normal normal normal 14px/1 Default-Icons;
    font-size: inherit;
    text-rendering: auto;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // to fix svg inside svg-icon-sprite
    svg-icon-sprite & {
        vertical-align: top;
    }
}

// icon-wt
.icon-wt {
    line-height: 0.8;
    vertical-align: baseline;

    &::before {
        transform: translateY(0.1em);
        display: block;
    }
}

// icon-fw
.icon-fw {
    width: (20em / 14);
    text-align: center;
}

// icon-2x
.icon-2x {
    font-size: 2em;
}

// icon-3x
.icon-3x {
    font-size: 3em;
}

// extra icons for Baza libs
.icon-down:before {
    &:extend(.icon-angle-down:before);
}
