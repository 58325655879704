// purchase-done
.purchase-done {
    padding: 0px 22px;

    @media (min-width: @screen-md) {
        padding: 0;
    }
}

// purchase-done section
.purchase-done__section {
    @media (min-width: @screen-lg) {
        .flex-col-width(8);
    }
}

// purchase-done icon
.purchase-done__icon {
    height: 64px;
    width: 64px;
    margin-bottom: 45px;
    margin-top: 45px;

    @media (min-width: @screen-lg) {
        margin-top: 0;
    }
}

// purchase-done title
.purchase-done__title {
    &:extend(h2);
}

// purchase-done description
.purchase-done__descr {
    color: @text-color;
    margin-right: 26px;
    font-size: 18px;

    a {
        color: @text-color;

        &:hover,
        &:active {
            color: @primary-color;
            text-decoration: underline;
        }
    }
}

// purchase-done button
.purchase-done__btn {
    margin-top: 20px;
    width: 100%;

    @media (min-width: @screen-md) {
        width: auto;
    }
}

// purchase-done summary
.purchase-done__summary {
    margin: 30px 0 70px;

    @media (min-width: @screen-lg) {
        .flex-col-width(4);
        margin: 0;
    }
}
