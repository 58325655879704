// .ant-form-item-vertical
.ant-form-item {
    .ant-form-vertical & {
        position: relative;
    }
}

// ant-form-item-label
.ant-form-item-label {
    font-weight: 500;
}

// ant-form-item-label-disabled
.ant-form-item-label {
    .ant-form-item-disabled & {
        opacity: 0.6;
    }
}

// ant-form-item-with-link
.ant-form-item-with-link {
    padding-bottom: 30px;

    &.ant-form-item-with-help {
        padding-bottom: 0;
    }
}

// ant-form-item-control
.ant-form-item-control {
    clear: both;
}

// ant-form-item-explain & -extra
.ant-form-item-explain,
.ant-form-item-extra {
    font-size: @font-size-sm;
    line-height: 2;
}

// ant-form-link
.ant-form-link {
    .layer(auto, 0, 0, auto);
    font-size: @font-size-sm;
    min-height: 26px;
}

// ant-form-submit
.ant-form-submit {
    margin-top: 32px;
}

// ant-form-note
.ant-form-note {
    text-align: center;
    padding-top: 16px;

    a {
        color: @primary-color;
        font-weight: 700;
        text-decoration: none;

        &:hover,
        &:active {
            color: #073ca6;
        }
    }
}
