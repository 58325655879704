// iframe-stripe
.iframe-stripe {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    margin: 0 auto;

    @media (min-width: @screen-md) {
        max-width: 460px;
    }
}

// iframe-stripe iframe
.iframe-stripe iframe {
    height: 340px;
    margin: -10% -15%;

    @media (min-width: @screen-sm) {
        height: 360px;
    }
    @media (min-width: @screen-md) {
        margin-bottom: -5%;
    }
}
