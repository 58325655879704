// ant-empty
.ant-empty {
    margin: 30px auto;
    max-width: 500px;

    @media (min-width: @screen-md) {
        margin: 60px auto;
    }
}

// ant-empty-image
.ant-empty-image {
    height: 80px;
    margin-bottom: 24px;

    .icon {
        height: 80px;
        width: 80px;
        font-size: 80px;
        color: @primary-2;
    }
}

// ant-empty-description
.ant-empty-description {
    &:extend(h3);
    margin-bottom: 16px;
}

// ant-empty-footer
.ant-empty-footer {
    color: @normal-color;
}
