// uploaded-file
.uploaded-file {
    font-size: @font-size-sm;
    color: @normal-color;
    margin-bottom: 20px;
}

// uploaded-file ssn-document
.uploaded-file__ssn-document {
    > a {
        word-break: break-word;
    }
}
