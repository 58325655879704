// table th
th {
    .ant-table-thead > tr > & {
        padding: 15px 16px;
        font-size: @font-size-base;
        font-weight: 700;
        border-bottom: none;

        &[colspan]:not([colspan='1']) {
            text-align: left;
        }
    }
}

td {
    .ant-table-tbody > tr > &,
    .ant-table tfoot > tr > & {
        padding: 24px 16px;
        font-size: @font-size-base;
        font-weight: 500;
    }
}

// table th & td
th,
td {
    .ant-table-thead > tr > &,
    .ant-table-tbody > tr > &,
    .ant-table tfoot > tr > & {
        @media (max-width: @screen-md-max) {
            font-size: @font-size-sm;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

// ant-table-row
.ant-table-row {
    &:hover {
        background-color: @background-color-light;
    }
}
