.card-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: @margin-sm;
    flex-grow: 1;
    flex-direction: column;

    &:last-child {
        margin-bottom: 0;
    }
}

.card-info__title {
    font-size: @font-size-lg;
    font-weight: @typography-title-font-weight;
    color: @text-color;
}

.card-info__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-base;
    color: @primary-color;
    font-weight: @typography-title-font-weight;
}

.card-info__name {
    color: @normal-color;
    font-weight: 700;
    margin-right: auto;
}

.card-info__amount {
    font-size: @font-size-base;
    font-weight: 700;
    color: @text-color;
}

.card-info__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    &:not(:last-child) {
        margin-bottom: 18px;
    }
}

.card-info__row-funded {
    align-items: start;
}

.card-info__col {
    display: flex;
    flex-direction: column;
    font-size: @font-size-sm;
}

// card-info alert
.card-info__alert {
    .ant-alert {
        border-radius: 6px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.card-info__funded-progress {
    width: 45%;
}

.card-info__buttons {
    display: flex;
    justify-content: flex-end;
}

// card-info favorite
.card-info__favorite {
    .layer(12px, 12px, auto, auto, 1);
    width: auto;

    > .ant-btn {
        background: rgba(@white, 0.5);
        border: none;
        height: 48px;
        width: 48px;

        &:hover,
        &:active,
        &:focus {
            background-color: rgba(@white, 0.7);
            color: @text-color;
        }
        .icon {
            font-size: 20px;
        }
    }
}
