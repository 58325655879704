// payment-subtitle-faded
.payment-subtitle-faded {
    color: @normal-color;
}

// payment-subtitle-faded-prefix
.payment-subtitle-faded {
    .payment-subtitle__prefix ~ & {
        margin-left: 20px;
    }
}
