// hero
.hero {
    background: linear-gradient(rgba(@black, 0.49), rgba(@black, 0.49)), url('/assets/images/hero-banner.jpeg') no-repeat center / cover;
    color: @white;
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// hero container
.hero__container {
    padding-top: 120px;
    padding-bottom: 80px;

    @media (min-width: @screen-xl) {
        padding-top: 150px;
        padding-bottom: 70px;
    }
}

// hero container-alert
.hero__container {
    .ant-layout-alert & {
        padding-top: 200px;

        @media (min-width: @screen-sm) {
            padding-top: 175px;
        }
        @media (min-width: @screen-xl) {
            padding-top: 205px;
        }
    }
}

// hero heading
.hero__heading {
    &:extend(h1);
    &:extend(h1.extra-md);
    color: inherit;
}

// hero subheading
.hero__subheading {
    font-size: @font-size-lg;
    margin-bottom: 30px;

    ul {
        margin-bottom: 0;
        padding-left: 20px;

        ::marker {
            color: @primary-color;
        }
    }
    li:not(:last-child) {
        margin-bottom: 20px;
    }
}

// hero column-text
.hero__col-text {
    margin-bottom: 40px;
    min-height: 400px;

    @media (min-width: @screen-md) {
        margin-bottom: 60px;
    }
    @media (min-width: @screen-lg) {
        .flex-col-width(7);
        margin-bottom: 0;
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(6);
    }
}

// hero column-image
.hero__col-img {
    background: url('/assets/images/hero-img.png') no-repeat center / contain;
    background-origin: content-box;
    height: 180px;

    @media (min-width: @screen-md) {
        height: 380px;
    }
    @media (min-width: @screen-lg) {
        .flex-col-width(5);
        height: auto;
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(6);
    }
}

// hero button
.hero__btn {
    .ant-btn {
        width: 100%;

        @media (min-width: @screen-sm) {
            width: auto;
        }
    }
}
