// payment-subtitle-hint
.payment-subtitle-hint {
    display: block;
    font-size: @font-size-sm;
    color: @normal-color;
    margin-top: 0;

    @media (min-width: @screen-md) {
        display: inline;
        margin-left: 6px;
    }
}
