// payment-funds heading
.payment-funds__heading {
    font-size: @font-size-lg;
    font-weight: 500;
    margin-bottom: 15px;
}

// payment-funds-form
.payment-funds-form {
    margin-bottom: @form-item-margin-bottom;
}

// payment-funds input
.payment-funds__input {
    width: 100%;
}

// payment-funds description
.payment-funds__descr {
    margin-bottom: 20px;
}
