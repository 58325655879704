// ant-tag
.ant-tag {
    border-radius: 4px;
    font-weight: 500;
}

// ant-tag-has-color
.ant-tag-has-color {
    color: @tag-default-color;
}
