// payment-actions
.payment-actions {
    display: flex;
    flex-grow: 1;

    @media (min-width: @screen-sm) {
        flex-grow: 0;
    }
}

// payment-actions button
.payment-actions {
    .ant-btn {
        flex-grow: 1;

        & + .ant-btn {
            margin-left: 16px;
        }
    }
}
