// hiw
.hiw {
    margin-bottom: 50px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// hiw heading
.hiw__heading {
    &:extend(h1);
    margin-bottom: 30px;
}

// hiw subheading
.hiw__subheading {
    margin-bottom: 30px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
        margin-bottom: 50px;
    }
    > p:last-of-type {
        margin: 0;
    }
}

// hiw column
.hiw__col {
    @media (min-width: @screen-md) {
        .flex-col-width(6);
    }
}

// hiw column-cards
.hiw__col-cards {
    @media (min-width: @screen-md) {
        .flex-col-width(12);
    }
    @media (min-width: @screen-lg) {
        .flex-col-width(4);
    }
}
