.verification {
    margin-top: 32px;

    @media (min-width: @screen-md) {
        margin-top: 0;
        padding: 0 60px;
    }

    @media (min-width: @screen-lg) {
        padding: 0 15px;
    }
}

.verification__section {
    .flex-col-width(12);
    margin: 0 auto;

    @media (min-width: @screen-lg) {
        .flex-col-width(9);
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(8);
    }
}

.verification__title {
    &:extend(h2);
}

.verification__heading {
    &:extend(h2);
    margin-top: 30px;
}

// verification subtitle
.verification__subtitle {
    font-size: @font-size-lg;
    font-weight: 700;
    margin: 40px 0 20px;

    .verification__title + & {
        margin-top: 55px;
    }
}

// verification description
.verification__descr {
    color: @text-color;
    margin-bottom: 20px;
    font-size: 16px;

    @media (min-width: @screen-md) {
        font-size: 18px;
    }
}

// verification divider
.verification__divider {
    margin: 15px 0 50px;
}

// verification icon
.verification__icon {
    margin-bottom: 40px;

    .icon {
        height: 58px;
        width: 58px;
    }
}

.verification__steps {
    .flex-col-width(12);
    margin: 0 auto;

    @media (min-width: @screen-lg) {
        .flex-col-width(9);
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(8);
    }
    .ant-steps {
        .flex-col-width(12);
    }
}

.verification__steps-action {
    margin: 0 auto;
    padding-top: 50px;

    @media (min-width: @screen-lg) {
        .flex-col-width(9);
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(8);
    }
}
