// ant-btn-loading
.ant-btn-loading {
    background: @primary-3;
    border-color: @primary-3;
    color: @btn-primary-color;

    &::before {
        content: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        pointer-events: none;
    }
}
