// banner
.banner {
    background: linear-gradient(rgba(@black, 0.58), rgba(@black, 0.58)), url('/assets/images/cta-banner.jpeg') no-repeat center / cover;
    color: @white;
    padding: 80px 0;
    text-align: center;

    @media (min-width: @screen-xl) {
        padding: 140px 0;
    }
}

// banner row
.banner__row {
    justify-content: center;
}

// banner column
.banner__col {
    @media (min-width: @screen-md) {
        .flex-col-width(10);
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(8);
    }
}

// banner subtitle
.banner__subtitle {
    font-size: @font-size-sm;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
}

// banner heading
.banner__heading {
    &:extend(h1);
    color: inherit;
    margin-bottom: 30px;
}

// banner subheading
.banner__subheading {
    max-width: 440px;
    margin: 0 auto 30px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
        max-width: 540px;
    }
    > p:last-of-type {
        margin: 0;
    }
}

// banner button
.banner__btn {
    .ant-btn {
        width: 100%;

        @media (min-width: @screen-sm) {
            width: auto;
        }
    }
}
