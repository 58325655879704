// ant-checkbox-checked
.ant-checkbox-checked {
    &:after {
        border-radius: 4px;
    }
}

// ant-checkbox-inner
.ant-checkbox-inner {
    border-radius: 4px;

    .ant-checkbox-wrapper:hover &,
    .ant-checkbox:hover &,
    .ant-checkbox-input:focus + & {
        border-color: @primary-color;
    }
}

// ant-checkbox-inner-checked
.ant-checkbox-inner {
    .ant-checkbox-checked & {
        background-color: @primary-color;
        border-color: @primary-color;

        &:after {
            border-width: 1.5px;
            top: 45%;
            left: 25%;
            width: 6px;
            height: 12px;
        }
    }
}

// ant-checkbox-inner-disabled
.ant-checkbox-inner {
    .ant-checkbox-disabled.ant-checkbox-checked & {
        border-color: @primary-color !important;
        opacity: 0.4;

        &:after {
            border-color: @white;
        }
    }
}

// ant-checkbox-wrapper
.ant-checkbox-wrapper {
    align-items: flex-start;
}
