.news-blur {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
}

.news-blur__icon {
    width: 60px;
    height: 60px;
}

.news-blur__text {
    margin-top: 20px;
    padding: 0 20px;
}
