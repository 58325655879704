// header-mini
.header-mini {
    border-bottom: 1px solid @neutral-5;
    background: @white;
    margin-bottom: 30px;

    @media (min-width: @screen-md) {
        .layer(0, 0, auto, 0);
        border: 0;
        margin-bottom: 0;
    }
}
