// cta
.cta {
    margin-bottom: 80px;
    text-align: center;
    color: @white;

    @media (min-width: @screen-xl) {
        margin-bottom: 100px;
    }
}

// cta wrap
.cta__wrap {
    background: @black;
    border-radius: 16px;
    padding: 50px 15px;

    @media (min-width: @screen-xl) {
        padding-top: 90px;
        padding-bottom: 90px;
    }
}

// cta heading
.cta__heading {
    &:extend(h1);
    color: inherit;
}

// cta description
.cta__descr {
    margin-bottom: 30px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
    }
}

// cta button
.cta__btn {
    .ant-btn {
        width: 100%;

        @media (min-width: @screen-sm) {
            width: auto;
        }
    }
}
