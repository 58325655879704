// payment-arrow
.payment-arrow {
    flex-shrink: 0;
    font-size: 15px;
    line-height: 1;
}

// payment-arrow button
.payment-arrow {
    .ant-btn {
        &:extend(.ant-btn-sm);
        &:extend(a.ant-btn-sm);
    }
}

// payment-arrow button-inactive
.payment-arrow {
    .payment-btn-inactive & .ant-btn {
        pointer-events: auto;
    }
}

// payment-arrow-centered
.payment-arrow-centered {
    .icon {
        position: relative;
        top: 22px;
    }
}
