// ant-menu-item-light
.ant-menu-item {
    .ant-menu-light:not(.ant-menu-submenu) & {
        a {
            &:hover,
            &:active,
            &:focus {
                color: @menu-item-color;

                @media (min-width: @screen-lg) {
                    border-bottom: solid 5px @primary-color;
                    color: @primary-color;
                }
            }
        }
    }
}

// ant-menu-item-selected-light
.ant-menu-item-selected {
    .ant-menu-light:not(.ant-menu-submenu) & {
        &,
        &:hover,
        &:active,
        &:focus {
            background: @neutral-4;

            @media (min-width: @screen-lg) {
                background: transparent;
            }
        }
        a {
            color: @menu-item-color;

            @media (min-width: @screen-lg) {
                border-bottom: solid 5px @primary-color;
            }
        }
    }
}
