// footer
.footer {
    text-align: center;
    font-size: @font-size-sm;
    color: @normal-color;
    position: relative;
    z-index: 99;

    @media (min-width: @screen-md) {
        padding-top: 50px;
    }
    a {
        color: @normal-color;
        text-decoration: none;

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}

// footer wrap
.footer__wrap {
    @media (min-width: @screen-md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

// footer divider
.footer__divider {
    border-top: solid 2px @neutral-10;
    margin: 30px 0;

    @media (min-width: @screen-md) {
        margin: 55px 0 35px;
    }
}

// footer copyright
.footer__copy {
    @media (min-width: @screen-md) {
        text-align: right;
    }
}
