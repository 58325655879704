// payment-section
.payment-section {
    display: flex;
    row-gap: 24px;
    flex-wrap: wrap;
    align-items: center;
    border: @border-width-base solid @border-color-base;
    border-radius: @border-radius-base;
    padding: 20px 24px;
    height: auto;
    min-height: 80px;

    @media (min-width: @screen-lg) {
        font-size: @font-size-lg;
        padding: 20px 32px;
        min-height: 100px;
    }
}

// payment-section title
.payment-section__title {
    display: flex;
    column-gap: 5px;
    align-items: center;
    flex-grow: 1;
    margin-right: 10px;
    text-transform: capitalize;
    overflow: hidden;
}

// payment-section title-block
.payment-section__title-block {
    display: block;
    font-weight: 700;
}

// payment-section description
.payment-section__desc {
    font-size: @font-size-base;
    color: @normal-color;
    font-weight: initial;
    white-space: normal;
    text-transform: none;
    margin-top: 11px;
}
