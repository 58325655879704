// investor-profile column
.investor-profile__col {
    @media (min-width: @screen-lg) {
        .flex-col-width(8);
    }
}

// investor-profile subheading
.investor-profile__subheading {
    &:extend(h4);
    margin-bottom: 24px;
}

// investor-profile kyc
.investor-profile__kyc {
    margin-bottom: 24px;
}
