// purchase-summary
.purchase-summary {
    margin-top: 30px;

    @media (min-width: @screen-lg) {
        margin: 0 0 10px;
    }
}

// purchase-summary card
.purchase-summary__card {
    border-radius: 8px;
}

// .purchase-summary card-body
.purchase-summary__card {
    .ant-card-body {
        padding: 24px;

        @media (min-width: @screen-md) {
            padding: 32px;
        }
    }
}

// purchase-summary title
.purchase-summary__title {
    &:extend(h3);
    margin-bottom: 24px;
}

// purchase-summary info
.purchase-summary__info {
    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
}

// purchase-summary row
.purchase-summary__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
    &:last-child {
        font-weight: 500;
    }
}

// purchase-summary row-preview
.purchase-summary__row {
    .purchase-summary__preview & {
        display: block;
        margin: 0;
    }
}

// purchase-summary image
.purchase-summary__img {
    margin-bottom: 20px;
    height: 190px;

    > img {
        .img-responsive-cover();
        border-radius: 14px;
    }
}

// purchase-summary subtitle
.purchase-summary__subtitle {
    &:extend(h4);
}

// purchase-summary label
.purchase-summary__label {
    color: @neutral-7;
}

// .purchase-summary divider
.purchase-summary__divider {
    border: none;
    border-bottom: solid 1px @border-color-split;
    margin: 20px 0 20px 0;
}

// purchase-summary fee
.purchase-summary__fee {
    display: inline;
}

// purchase-summary icon
.purchase-summary__icon {
    font-size: @font-size-sm;
    color: @normal-color;
    margin-left: 8px;
    cursor: pointer;
}
