// footer-mini
.footer-mini {
    text-align: left;
    padding: 20px 0;

    @media (min-width: @screen-md) {
        padding: 32px 0;
    }
    a {
        color: @white;
    }
}
