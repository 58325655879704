// bullets label
.bullets__label {
    .bullets-img & {
        &:extend(h4);
        margin-bottom: 20px;

        @media (min-width: @screen-lg) {
            .flex-col-width(2);
            margin-bottom: 0;
            padding-left: 20px;
        }
    }
}

// bullets image
.bullets__image {
    .bullets-img & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        margin: 0 -15px;

        @media (min-width: @screen-lg) {
            flex-grow: 1;
        }
    }
}

// bullets image-column
.bullets__imgcol {
    .bullets-img & {
        padding: 0 10px;
        margin-bottom: 24px;
        text-align: center;

        @media (min-width: @screen-md) {
            margin-bottom: 0;
        }
        > img {
            height: 36px;
            max-width: 100%;

            @media (min-width: @screen-md) {
                height: 38px;
            }
            @media (min-width: @screen-xl) {
                height: 47px;
            }
        }
    }
}
