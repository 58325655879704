.filter-mobile__collapse .ant-collapse-header {
    position: relative;
    background-color: #fff;
    padding: 0 20px;
    height: 70px;
    display: flex;
    align-items: center;
}

.filter-mobile__collapse .ant-collapse-header {
    border-top: 1px solid @neutral-5;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.filter-mobile__collapse.is-opened .ant-collapse-header {
    border-top: 1px solid @neutral-5;
    border-bottom: 0;
}

.filter-mobile__collapse .ant-collapse-header .anticon {
    position: absolute;
    right: 20px;
}

.filter-mobile__collapse .ant-collapse-content {
    background-color: @white;
    padding: 0 20px;
    border-bottom: 1px solid @neutral-5;
}

.filter-mobile__icon {
    transition: all 0.3s ease-in-out;
    font-size: 11px;
}

.ant-collapse-item-active {
    .filter-mobile__icon {
        transform: rotate(180deg);
    }
}
