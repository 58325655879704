@font-face {
    font-family: 'Satoshi';
    src: url('/assets/fonts/Satoshi-Regular.eot');
    src: url('/assets/fonts/Satoshi-Regular.woff2') format('woff2'), url('/assets/fonts/Satoshi-Regular.woff') format('woff'),
        url('/assets/fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('/assets/fonts/Satoshi-Medium.eot');
    src: url('/assets/fonts/Satoshi-Medium.woff2') format('woff2'), url('/assets/fonts/Satoshi-Medium.woff') format('woff'),
        url('/assets/fonts/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: 'Satoshi';
    src: url('/assets/fonts/Satoshi-Bold.eot');
    src: url('/assets/fonts/Satoshi-Bold.woff2') format('woff2'), url('/assets/fonts/Satoshi-Bold.woff') format('woff'),
        url('/assets/fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}
