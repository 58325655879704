// ant-btn-circle
.ant-btn-circle {
    transition: background-color 0.3s ease-in-out;
    height: 32px;
    width: 32px;
    min-width: 32px;
    padding: 0 0 2px;

    @media (min-width: @screen-md) {
        height: 48px;
        width: 48px;
    }
}

// ant-btn-circle icon
.ant-btn.ant-btn-circle {
    .icon {
        font-size: @font-size-base;

        @media (min-width: @screen-md) {
            font-size: 20px;
        }
    }
}
