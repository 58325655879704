// payment-btn-details
.payment-btn-details {
    flex-wrap: nowrap;
    padding: 0 0 24px;
    min-height: 0;
}

// payment-btn title-details
.payment-btn__title {
    .payment-btn-details & {
        font-size: @font-size-base;
        font-weight: 500;
        margin-bottom: 4px;
    }
}

// payment-btn row-details
.payment-btn__row {
    .payment-btn-details & {
        width: 100%;
    }
}
