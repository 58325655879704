// tax-doc item
.tax-doc__item {
    margin-bottom: 30px;
}

// tax-doc title
.tax-doc__title {
    margin-bottom: 8px;
}

// tax-doc description
.tax-doc__desc {
    font-size: @font-size-sm;
    color: @normal-color;
}
