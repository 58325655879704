// ant-pagination
.ant-pagination {
    margin-top: 30px;
    text-align: center;

    @media (min-width: @screen-md) {
        margin-top: 50px;
    }
}

// ant-pagination-item
.ant-pagination-item {
    border-radius: 4px;
    font-weight: 500;

    &,
    &:focus,
    &:hover,
    &:active {
        a {
            text-decoration: none;
            color: @text-color;
        }
    }
}

// ant-pagination-item-active
.ant-pagination-item-active {
    &,
    &:focus,
    &:hover,
    &:active {
        border-color: @pagination-item-bg-active;

        a {
            color: @white;
        }
    }
}

// ant-pagination-item-link
.ant-pagination-prev,
.ant-pagination-next {
    .ant-pagination-item-link {
        border-radius: 4px;
        color: @normal-color;
        line-height: 1;
    }
    &:focus,
    &:hover,
    &:active {
        .ant-pagination-item-link {
            color: @text-color;
        }
    }
}

// ant-pagination-item-link-disabled
.ant-pagination-disabled {
    .ant-pagination-item-link {
        background-color: @pagination-item-disabled-bg-active;
        border-color: @pagination-item-disabled-bg-active;
        opacity: 0.5;
    }
    &:focus,
    &:hover,
    &:active {
        .ant-pagination-item-link {
            border-color: @pagination-item-disabled-bg-active;
            color: @normal-color;
        }
    }
}

// ant-pagination-item-link-icon
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    .ant-pagination-item-container {
        .ant-pagination-item-ellipsis,
        .ant-pagination-item-link-icon {
            color: @text-color;
        }
    }
}
