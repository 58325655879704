// payment-block
.payment-block {
    margin-bottom: 50px;

    .payment-methods & {
        margin-top: 0;
    }
}

// payment-block row
.payment-block__row {
    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

// payment-block heading
.payment-block__heading {
    &:extend(h3);
}

// payment-block alert
.payment-block__alert {
    margin-bottom: 20px;
}
