// ant-tabs-nav
.ant-tabs-nav {
    .ant-tabs-top > &,
    .ant-tabs-bottom > &,
    .ant-tabs-top > div > &,
    .ant-tabs-bottom > div > & {
        &:before {
            border-width: 1px;
        }
    }
}

// ant-tabs-tab
.ant-tabs-tab {
    .ant-tabs-nav & {
        font-weight: 700;

        @media (min-width: @screen-md) {
            font-size: @font-size-lg;
        }
        &:hover,
        &:active {
            color: @primary-color;
        }
        & + .ant-tabs-tab {
            margin-left: 40px;
        }
    }
}

// active tab bottom border
.ant-tabs-ink-bar {
    .ant-tabs-top > .ant-tabs-nav &,
    .ant-tabs-bottom > .ant-tabs-nav &,
    .ant-tabs-top > div > .ant-tabs-nav &,
    .ant-tabs-bottom > div > .ant-tabs-nav & {
        height: 4px;
    }
}

// ant-tabs-bar
.ant-tabs-bar {
    margin-bottom: 20px;
}

// ant-tabs-content
.ant-tabs-content {
    padding-top: 30px;

    @media (min-width: @screen-lg) {
        padding-top: 45px;
    }
}

// small device tab btn
.ant-tabs-tab-btn {
    @media (max-width: @screen-sm-max) {
        font-size: @font-size-base;
    }
}

// ant-tabs-icon
.ant-tabs-icon {
    font-size: 20px;
    color: @warning-color;
    margin-left: 8px;
}
