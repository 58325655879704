.ant-progress {
    line-height: 1;
}

.ant-progress-show-info .ant-progress-outer {
    padding-right: 0;
}

.ant-progress--squared {
    .ant-progress-inner {
        border-radius: 0;
    }
}

.ant-progress-line {
    .ant-progress-inner {
        background: @neutral-4;
    }
}
