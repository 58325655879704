.payment-collapse__panel {
    position: relative;

    .ant-collapse-arrow {
        display: none;
    }
}

.payment-collapse .payment-collapse__panel.ant-collapse-item-active .icon {
    transform: rotate(180deg);
}

.payment-collapse__panel {
    .ant-collapse-content {
        .payment-item__btn {
            border-radius: 0 0 10px 10px;
            margin-top: -2px;
        }
    }
}

.payment-collapse__panel.ant-collapse-item-active {
    .ant-collapse-header {
        .payment-item__btn {
            border-radius: 10px 10px 0 0;
        }
    }
}

.payment-collapse .ant-collapse-item-active .ant-collapse-header .payment-section {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.payment-collapse .payment-collapse__panel .ant-collapse-content .payment-section {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
