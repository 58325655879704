.verification-info__first-name {
    @media (min-width: @screen-sm) {
        .flex-col-width(6);
    }
}

.verification-info__last-name {
    @media (min-width: @screen-sm) {
        .flex-col-width(6);
    }
}

.verification-info__state {
    @media (min-width: @screen-sm) {
        .flex-col-width(8);
    }
}

.verification-info__zip {
    @media (min-width: @screen-sm) {
        .flex-col-width(4);
    }
}

.verification-info__dob {
    @media (min-width: @screen-sm) {
        .flex-col-width(4);
    }
}

.verification-info__citizenship {
    @media (min-width: @screen-sm) {
        .flex-col-width(8);
    }
}

.verification-info__ssn {
    margin-bottom: 25px;
}

.verification-info__hasntSsn {
    margin-bottom: 0;
}

.verification-info__file {
    > div {
        font-size: @font-size-sm;
        color: @normal-color;
        margin-bottom: 20px;
    }
}
