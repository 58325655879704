// payment-subtitle-cta
.payment-subtitle-cta {
    display: inline-block;
    font-size: @font-size-base;
    margin-top: 20px;

    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
}
