.flex-col-width(@columns) {
    flex: 0 0 percentage((@columns / @grid-columns));
    max-width: percentage((@columns / @grid-columns));
}

.flex-col-push(@columns) {
    left: percentage((@columns / @grid-columns));
}

.flex-col-pull(@columns) {
    right: percentage((@columns / @grid-columns));
}
