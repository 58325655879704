// invest-card
.invest-card {
    margin-bottom: 24px;

    @media (min-width: @screen-md) {
        margin-bottom: 55px;
    }
}

// invest-card body
.ant-card-body {
    .invest-card & {
        background-color: @neutral-3;
        padding: 0;
    }
}

// invest-card index
.invest-card__index {
    margin-bottom: 16px;
}

// invest-card icon
.invest-card__icon {
    width: 40px;
    height: 40px;
}

// invest-card title
.invest-card__title {
    &:extend(h3);
    margin-bottom: 8px;

    @media (min-width: @screen-md) {
        margin-bottom: 16px;
    }
}

// invest-card description
.invest-card__descr {
    font-size: @font-size-sm;

    @media (min-width: @screen-md) {
        font-size: @font-size-base;
    }
    > p:last-of-type {
        margin: 0;
    }
}
