// ant-menu-horizontal
.ant-menu-horizontal {
    border-bottom: none;
}

// ant-menu-item-horizontal
.ant-menu-item {
    .ant-menu-horizontal > &,
    .ant-menu-horizontal:not(.ant-menu-dark) > & {
        &,
        &:hover {
            margin-top: 0;
            top: 0;

            &:after {
                border-bottom: none;
            }
        }
    }
}
