// withdraw
.withdraw {
    @media (min-width: @screen-xs) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

// withdraw wrap
.withdraw__wrap {
    &:not(:last-child) {
        margin-bottom: 20px;

        @media (min-width: @screen-xs) {
            margin-bottom: 0;
        }
    }
}

// withdraw value
.withdraw__value {
    margin-bottom: 4px;
}

// withdraw value-cents
.withdraw__value {
    span {
        font-size: @font-size-xs;
        color: @normal-color;
    }
}

// withdraw description
.withdraw__descr {
    color: @normal-color;
}
