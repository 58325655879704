// purchase-checklist
.purchase-checklist {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: @font-size-lg;
}

// purchase-checklist item
.purchase-checklist__item {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

// purchase-checklist icon
.purchase-checklist__icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
}
