// ant-alert-card
.ant-alert {
    .ant-alert-card & {
        padding: 12px;
    }
}

// ant-alert-card-success
.ant-alert-success {
    .ant-alert-card & {
        background-color: @success-6;
    }
}

// ant-alert-card-warning
.ant-alert-warning {
    .ant-alert-card & {
        background-color: @success-1;
    }
}

// ant-alert-card-info
.ant-alert-info {
    .ant-alert-card & {
        background-color: @info-5;
    }
}

// ant-alert-card content
.ant-alert-content {
    .ant-alert-card & {
        text-align: center;
    }
}

// ant-alert-card message
.ant-alert-message {
    .ant-alert-card & {
        font-weight: 700;
        color: @white;
    }
}

// ant-alert-card message-warning
.ant-alert-message {
    .ant-alert-card .ant-alert-warning & {
        color: @success-7;
    }
}

// ant-alert-card icon
.ant-alert-icon {
    .ant-alert-card & {
        font-size: @font-size-lg;
        color: inherit;
    }
}
