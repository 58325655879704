// header
.header {
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid @neutral-5;
    box-shadow: none;
}

// header container
.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media (max-width: @screen-md-max) {
        position: static;
    }
}

// header logo
.header__logo {
    margin-right: 50px;
}

// header icon
.header__icon {
    font-size: 11px;
    margin-left: 5px;
}

// header wrap
.header__wrap {
    .layer(0, 0, auto, 0, -1);
    transition: transform 0.3s ease-in-out;
    flex-grow: 1;
    flex-direction: column;
    background: @white;
    transform: translateY(-150%);
    height: 100vh;
    padding-top: @layout-header-height;
    overflow-y: auto;
    opacity: 0;

    @media (min-width: @screen-lg) {
        display: flex;
        flex-direction: row;
        background: transparent;
        transform: translateY(0);
        height: auto;
        padding: 0;
        position: static;
        z-index: auto;
        overflow: visible;
        opacity: 1;
    }
}

// header wrap-alert
.header__wrap {
    .ant-layout-alert & {
        padding-top: @layout-header-height + 80px;

        @media (min-width: @screen-sm) {
            padding-top: @layout-header-height + 55px;
        }
        @media (min-width: @screen-lg) {
            padding: 0;
        }
    }
}

// header wrap-open
.header__wrap-open {
    display: flex;
    transform: translateY(0);
    opacity: 1;
}

// header action
.header__action {
    padding: 0 15px 30px;

    @media (min-width: @screen-lg) {
        padding: 0;
    }
}

// header button
.header__btn {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: auto;

    @media (min-width: @screen-lg) {
        display: inline-block;
        width: auto;
    }
    & + & {
        margin-top: 16px;

        @media (min-width: @screen-lg) {
            margin: 0 0 0 20px;
        }
    }
}

// header user
.header__user {
    font-style: normal;
}
