// header-icon
.header-icon {
    border: none;
    background-color: transparent;
    width: 24px;
    height: 16px;
    display: block;
    position: relative;
    cursor: pointer;

    @media (min-width: @screen-lg) {
        display: none;
    }
    &:focus {
        outline: none;
    }
}

// line
.line {
    .layer(auto, auto, auto, 0);
    transition: all 0.3s ease-in-out;
    background: @text-color;
    border-radius: 1px;
    display: block;
    width: 100%;
    height: 2px;
}

// line-ghost
.line {
    .header-icon-ghost & {
        background: @white;
    }
}

// line-1
.line-1 {
    top: 0;
}

// line-1-open
.line-1 {
    .header-icon-open & {
        transform: translateY(8px) translateX(0) rotate(45deg);
    }
}

// line-2
.line-2 {
    top: 50%;
}

// line-2-open
.line-2 {
    .header-icon-open & {
        opacity: 0;
    }
}

// line-3
.line-3 {
    top: 100%;
}

// line-3-open
.line-3 {
    .header-icon-open & {
        transform: translateY(-8px) translateX(0) rotate(-45deg);
    }
}
