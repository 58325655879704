// payment-btn-list
.payment-btn-list {
    row-gap: 12px;
    flex-wrap: nowrap;
    border: 0;
    border-bottom: solid 1px @border-color-split;
    border-radius: 0;
    font-size: @font-size-base;
    white-space: normal;
    padding: 24px 0;
    min-height: 0;

    &:first-of-type {
        padding-top: 0;
    }
    &:focus,
    &:hover,
    &:active {
        background: @white;
    }
    &.payment-btn-inactive {
        &:focus,
        &:hover,
        &:active {
            border-color: @border-color-split;
        }
    }
    &.payment-btn-fluid {
        flex-wrap: wrap;
    }
}
