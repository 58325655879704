// check-account
.check-account {
    @media (min-width: @screen-md) {
        padding-top: 30px;
    }
}

// check-account box
.check-account__box {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: @screen-md) {
        .flex-col-width(10);
        background: @white;
        border: solid 1px @neutral-4;
        border-radius: @border-radius-base;
        padding: 60px 40px;
        margin: 0 auto;
    }
}

// check-account icon
.check-account__icon {
    transform: scale(0.7);
    transform-origin: top left;
    height: 80px;
    width: 80px;

    @media (min-width: @screen-sm) {
        transform: scale(1);
        margin-bottom: 30px;
    }
}

// check-account title
.check-account__title {
    &:extend(h2);
}

// check-account description
.check-account__descr {
    margin-bottom: 30px;

    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
    > p:last-of-type {
        margin: 0;
    }
}

// check-account continue
.check-account__continue {
    width: 100%;

    @media (min-width: @screen-sm) {
        width: auto;
    }
}
