// ant-radio-wrapper
.ant-radio-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

// ant-radio-inner-disabled
.ant-radio-inner {
    .ant-radio-checked.ant-radio-disabled & {
        background-color: @white;
        border-color: @primary-color !important;
        opacity: 0.4;

        &:after {
            background-color: @primary-color;
        }
    }
}

// ant-radio-title
.ant-radio-title {
    display: block;
    margin-bottom: 10px;
}

// ant-radio-description
.ant-radio-description {
    font-size: @font-size-sm;
    color: @normal-color;
    white-space: normal;
}
