// payment-item-content
.payment-item-content {
    flex-grow: 1;
    padding-right: 10px;
}

// payment-item-content header
.payment-item-content__header {
    font-weight: 700;
}
