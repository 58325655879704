// txt-sect
.text-section {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        font-size: 20px;
    }
    @media (min-width: @screen-xl) {
        margin-bottom: 100px;
    }
}

// txt-sect column
.text-section__col {
    @media (min-width: @screen-md) {
        .flex-col-width(6);
    }
}

// txt-sect container
.text-section__container {
    margin-bottom: 24px;

    @media (min-width: @screen-md) {
        margin-bottom: 0;
    }
    @media (min-width: @screen-lg) {
        padding-right: percentage((2 / @grid-columns));
    }
}

// txt-sect container-right
.text-section__container-right {
    margin-bottom: 0;

    @media (min-width: @screen-lg) {
        padding-right: 0;
    }
}

// txt-sect heading
.text-section__heading {
    &:extend(h1);
    margin-bottom: 30px;
}

// txt-sect description
.text-section__descr {
    ul {
        margin-bottom: 0;
        padding-left: 18px;

        ::marker {
            color: @primary-color;
        }
    }
    li {
        margin-bottom: 2px;
    }
}
