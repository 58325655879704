// info-header
.info-header {
    margin-bottom: 20px;
}

// info-header-investor-profile
.info-header {
    .investor-profile & {
        margin-bottom: 40px;
    }
}

// info-header title
.info-header__title {
    &:extend(h3);
    display: inline;
    margin: 0 10px 0 0;
}

// info-header icon
.info-header__icon {
    font-size: @font-size-lg;
    display: inline;

    a {
        color: @neutral-7;

        &:hover,
        &:active {
            color: darken(@neutral-7, 15%);
        }
    }
}
