// item-actions
.item-actions {
    flex-shrink: 0;
    margin-left: 15px;
}

// item-actions favorite
.item-actions__favorite {
    margin-left: 10px;

    @media (min-width: @screen-md) {
        margin-left: 20px;
    }
}
