// ant-menu-submenu
.ant-menu-submenu {
    font-size: @font-size-base;

    &.ant-menu-submenu-placement-bottom {
        top: -30px;
    }
    .ant-menu-horizontal > & {
        &:after {
            content: unset;
        }
    }
}

// ant-menu-submenu-active
.ant-menu-submenu-active {
    .ant-menu-horizontal > & {
        color: @primary-color;
    }
}

// ant-menu-submenu-selected
.ant-menu-submenu-selected {
    .ant-menu-horizontal:not(.ant-menu-dark) > & {
        color: @menu-item-color;
    }
}

// ant-menu-sub
.ant-menu-sub {
    .ant-menu-submenu & {
        background: @white;
        border-radius: 4px;
        min-width: 150px;
        padding: 8px 0;
    }
}

// ant-menu-submenu-title
.ant-menu-submenu-title {
    font-weight: 700;

    .ant-menu-horizontal & {
        transition: all 0.3s ease-in-out;
    }
    .ant-menu-light &:hover,
    .ant-menu-dark &:hover {
        color: @primary-color;
    }
}

// ant-menu-submenu-items
.ant-menu-item {
    .ant-menu-submenu &,
    .ant-menu-dark.ant-menu-submenu & {
        font-weight: 500;
        padding: 0 12px;

        &:not(:last-child) {
            margin: 0;
        }
        &:hover,
        &:active {
            background: @menu-item-active-bg;
        }
        > a {
            display: block;

            &,
            &:hover,
            &:active {
                color: @menu-item-color;
            }
        }
    }
}
