// item heading
.item__heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 25px;

    @media (min-width: @screen-md) {
        margin-bottom: 50px;
    }
}

// item title
.item__title {
    align-self: center;
    margin-bottom: 0;
}

// item description
.item__descr {
    order: 3;
    margin-bottom: 40px;

    @media (min-width: @screen-md) {
        margin-bottom: 56px;
    }

    p {
        font-size: 16px;

        @media (min-width: @screen-md) {
            font-size: @font-size-lg;
        }
    }
    h2,
    h3,
    h4 {
        margin-top: 40px;

        @media (min-width: @screen-md) {
            margin-top: 56px;
        }
    }
    img {
        .img-responsive();
    }
}

// item__gallery-map
.item__gallery-map {
    width: 80px;
    height: 80px;

    position: absolute;
    bottom: 10px;
    right: 10px;

    @media (min-width: @screen-sm) {
        width: 110px;
        height: 110px;
        display: block;
    }

    @media (min-width: @screen-lg) {
        width: 140px;
        height: 140px;
    }

    /*======= Google Map Styling =======*/
    & .gmnoprint {
        display: none;
    }

    & .gm-style a {
        display: none !important;
    }
}
