// heading
.heading {
    display: flex;
    flex-direction: column;

    @media (min-width: @screen-md) {
        flex-direction: row;
        justify-content: space-between;
    }
}

// heading title
.heading__title {
    margin: 0;
}

// heading value
.heading__value {
    padding-top: 8px;
}

// heading description
.heading__desc {
    color: @normal-color;
    padding-top: 8px;
}

// heading controls
.heading__controls {
    padding-top: 24px;

    @media (min-width: @screen-md) {
        padding-top: 0;
    }
}
