// ant-btn-sm
.ant-btn-sm {
    .button-size(@btn-height-sm; @btn-padding-horizontal-sm; @btn-font-size-sm; @btn-border-radius-sm);
}

// ant-btn-sm link
a.ant-btn-sm {
    padding-top: 0.01px !important;
    line-height: @btn-height-sm - 2px;
}
