// hero-item
.hero {
    .hero-item & {
        background: #182f44;
        margin-bottom: 0;
    }
}

// hero-item container
.hero__container {
    .hero-item & {
        padding-bottom: 20px;

        @media (min-width: @screen-md) {
            padding-top: 170px;
            padding-bottom: 130px;
        }
        @media (min-width: @screen-xl) {
            padding-bottom: 100px;
        }
    }
}

// hero-item heading
.hero__heading {
    .hero-item & {
        &:extend(h1.extra-md);

        @media (max-width: @screen-sm-max) {
            margin-bottom: 10px;
        }
    }
}

// hero subheading
.hero__subheading {
    .hero-item & {
        font-size: @font-size-lg;

        @media (min-width: @screen-md) {
            font-size: 20px;
        }
    }
}

// .hero-item col-text
.hero__col-text {
    .hero-item & {
        min-height: 0px;
        margin-bottom: 0;

        @media (min-width: @screen-lg) {
            .flex-col-width(9);
        }
        @media (min-width: @screen-xl) {
            .flex-col-width(8);
        }
    }
}

// hero-item col-img
.hero__col-img {
    .hero-item & {
        display: none;
    }
}

// hero button
.hero__btn {
    .hero-item & {
        display: none;
    }
}
