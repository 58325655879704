// ant-select-selector
.ant-select-selector {
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) & {
        box-shadow: none;
        border-color: @primary-6;
    }
}

// ant-select-item
.ant-select-item {
    color: @text-color;
}

// ant-select-arrow
.ant-select-arrow {
    transition: color 0.3s ease-in-out;
    width: 11px;
    height: 11px;
    margin-top: -5px;
    font-size: 11px;
    color: @text-color;

    .ant-select-open & {
        color: @normal-color;
    }
    .ant-select-disabled & {
        color: @normal-color;
    }
}
