// auth-page
.auth-page {
    margin: 0 auto;
    padding: 0 30px;

    @media (min-width: @screen-md) {
        background: @white;
        border: @border-width-base solid @border-color-base;
        border-radius: @border-radius-lg;
        padding: 50px 45px;
        max-width: 560px;
    }
}

// auth-page title
.auth-page__title {
    margin-bottom: 30px;
}

// auth-page description
.auth-page__descr {
    font-size: @font-size-lg;
    color: @normal-color;
    margin-bottom: 30px;
}
