// ant-input
.ant-input {
    &:focus,
    .ant-input-focused {
        box-shadow: none;
    }
}

// ant-input-error
.ant-input {
    .ant-form-item-has-error &:not(.ant-input-disabled):not(.ant-input-borderless) {
        &:focus {
            box-shadow: none;
        }
    }
}

// input-autocomplete
input {
    &:-webkit-autofill {
        &,
        &:hover,
        &:focus {
            transition: border-color 0.3s ease-in-out, background-color 5000s ease-in-out;
            box-shadow: 0 0 0 1000px @input-bg inset;
            -webkit-text-fill-color: @text-color;
        }
    }
}

// ant-input-affix
input.ant-input {
    .ant-input-affix-wrapper > & {
        border-radius: 0;
    }
}
