// payment-button
.payment-btn {
    display: flex;
    row-gap: 24px;
    flex-wrap: wrap;
    align-items: center;
    border-radius: @border-radius-base;
    font-weight: 400;
    text-transform: none;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    height: auto;
    min-height: 80px;

    @media (min-width: @screen-lg) {
        font-size: @font-size-lg;
        padding-left: 32px;
        padding-right: 32px;
        min-height: 100px;
    }

    &[disabled] {
        opacity: 1;

        &,
        &:focus,
        &:hover,
        &:active {
            background: transparent;
        }

        .payment-btn__icon {
            color: @neutral-7;
        }

        .payment-btn__arrow {
            display: inline-flex;
            opacity: 0.2;
        }
    }

    & + & {
        margin-top: 0;
    }
}

// payment-btn funds-modal
.payment-btn {
    .payment-funds-modal & {
        border: 0;
    }
}

// payment-btn heading
.payment-btn__heading {
    font-size: @font-size-lg;
    font-weight: 700;
    margin-bottom: 16px;
}

// payment-btn title
.payment-btn__title {
    flex-grow: 1;
    font-weight: 500;
    margin-right: 10px;
}

// payment-btn description
.payment-btn__descr {
    white-space: normal;
    font-size: @font-size-base;
}

// payment-btn description-error
.payment-btn__descr-error {
    color: @error-color;
}

// payment-btn icon
.payment-btn__icon {
    flex-shrink: 0;
    margin-right: 0;
    font-size: 28px;

    .icon {
        color: @neutral-7;
    }
}

// payment-btn icon-dwolla
.payment-btn__icon-dwolla {
    margin-right: 0;
}
