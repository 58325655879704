// hiw-card
.hiw-card {
    background: @primary-2;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 30px;

    @media (min-width: @screen-md) {
        display: flex;
    }
    @media (min-width: @screen-lg) {
        display: block;
        margin-bottom: 0px;
        height: 100%;
    }
}

// hiw-card card
.hiw-card {
    .ant-card {
        background: none;
    }
}

// hiw-card body
.hiw-card {
    .ant-card-body {
        padding: 0;

        @media (min-width: @screen-md) and (max-width: @screen-md-max) {
            flex-direction: row;
        }
    }
}

// hiw-card index
.hiw-card__index {
    margin-bottom: 30px;

    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        margin-right: 24px;
    }
}

// hiw-card number
.hiw-card__number {
    background-color: @primary-color;
    border-radius: 20px;
    font-weight: 700;
    color: @white;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
}

// hiw-card title
.hiw-card__title {
    &:extend(h3);
    margin-bottom: 24px;

    @media (min-width: @screen-md) and (max-width: @screen-md-max) {
        margin-bottom: 16px;
    }
    &:after {
        @media (max-width: @screen-sm-max), (min-width: @screen-lg) {
            display: block;
            content: '';
            border-bottom: 1px solid @black;
            width: 45px;
            height: 1px;
            margin-top: 24px;
        }
    }
}

// hiw-card description
.hiw-card__descr {
    > p:last-of-type {
        margin: 0;
    }
}
