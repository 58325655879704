// ant-btn-dangerous
.ant-btn-dangerous {
    color: @error-color;
    border-color: @error-color;

    &:focus,
    &:hover,
    &:active {
        background: @error-color;
        color: @white;
        border-color: @error-color;
    }

    &[disabled],
    &[disabled]:hover {
        color: @error-3;
        border-color: @error-3;
    }
}

// ant-btn-dangerous-primary
.ant-btn-dangerous {
    &.ant-btn-primary {
        &[disabled],
        &[disabled]:hover {
            background: #f5857a;
            border-color: #f5857a;
        }
        &:hover,
        &:focus,
        &:active {
            background: #8f0b12;
            border-color: #8f0b12;
        }
    }
}
