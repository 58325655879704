// ant-btn-primary
.ant-btn-primary {
    &:focus,
    &:hover,
    &:active {
        background: @primary-7;
        border-color: @primary-7;
        color: @btn-primary-color;
    }
    &[disabled] {
        &,
        &:focus,
        &:hover,
        &:active {
            background: @primary-3;
            border-color: @primary-3;
            color: @btn-primary-color;
        }
    }
}
