// icons inside ant-btn
.ant-btn {
    .icon {
        font-size: 20px;
    }
    & > .icon + span,
    & > span + .icon {
        margin-left: 8px;
    }
}

// icons inside ant-btn-sm
.ant-btn-sm {
    .icon {
        font-size: @font-size-base;
    }
}
