// back-link
.back-link {
    padding-bottom: 5px;

    @media (min-width: @screen-md) {
        padding-bottom: 20px;
    }
}

// back-link-mini
.back-link {
    .header-mini & {
        padding: 48px 0 16px;

        @media (min-width: @screen-md) {
            padding: 36px 0;
        }
    }
}

// back-link link
.back-link__link {
    font-weight: 500;
    color: @text-color;
    text-decoration: none;

    &:active,
    &:hover {
        color: darken(@text-color, 15%);
        text-decoration: none;
    }
}

// back-link icon
.back-link__icon {
    font-size: 12px;
    margin-right: 5px;
}
