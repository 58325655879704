// nz-upload
nz-upload {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-bottom: 10px;
    width: 100%;

    @media (min-width: @screen-sm) {
        flex-direction: row-reverse;
    }
}

// ant-upload-select
.ant-upload-select {
    margin-top: 25px;

    @media (min-width: @screen-sm) {
        margin-top: 0;
    }
}

// ant-upload-list-item-info-hover
.ant-upload-list-item-info {
    .ant-upload-list-item:hover & {
        background-color: transparent;
    }
}

// ant-upload-text-icon
.ant-upload-text-icon {
    display: none;
}

// ant-upload-list-text
.ant-upload-list-text {
    @media (min-width: @screen-sm) {
        max-width: 65%;
    }
}

// ant-upload button
.ant-upload {
    .ant-btn {
        &:extend(.ant-btn-sm);
    }
}

// ant-upload-list-item-card-actions-btn
.ant-upload-list-item-card-actions-btn {
    &:extend(.ant-btn-dangerous);
    border: none;
    opacity: 1;

    &.ant-btn-sm {
        height: 36px;

        &:hover,
        &:focus {
            background: none;
            color: darken(@error-color, 15%);
        }
    }
}

// ant-upload-list-item-card-actions-icon
.anticon {
    .ant-upload-list-item-card-actions-btn & {
        font-size: @font-size-lg;
        color: @error-color;
    }
}

// ant-upload-list-item
.ant-upload-list-item {
    margin-right: 10px;
    margin-top: 4px;
}

// ant-upload-list-item-name
.ant-upload-list-item-name {
    margin-right: 4px;

    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
    .ant-upload-list-text &,
    .ant-upload-list-picture & {
        padding: 0;
    }
}
