// ant-modal-transparent
.ant-modal-transparent {
    min-width: 360px;
}

// ant-modal-body-transparent
.ant-modal-body {
    .ant-modal-transparent & {
        padding: 0px;
    }
}
