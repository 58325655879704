// heading-form
.heading-form {
    padding-top: 16px;
}

// heading-form controls
.heading-form__controls {
    padding-top: 4px;
    text-align: right;
}

// heading-form button
.heading-form__btn {
    & + & {
        margin-left: 15px;
    }
}
