// header-menu
.header-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 30px 0;

    @media (min-width: @screen-sm) {
        text-align: center;
    }
    @media (min-width: @screen-lg) {
        flex-direction: row;
        padding: 0;
        margin: 0 -20px;
        text-align: left;
    }
}

// header-menu-item
.ant-menu-item {
    .header-menu & {
        display: block;
        font-weight: 700;
        line-height: 76px;

        @media (min-width: @screen-lg) {
            display: inline-block;
            line-height: inherit;
        }
        &:after {
            content: unset;
        }
        a {
            @media (min-width: @screen-lg) {
                padding-bottom: 8px;
            }
            &:before {
                content: unset;
            }
        }
    }
}

// header-menu space
.header-menu__space {
    flex-grow: 1;
}
