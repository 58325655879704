// TODO: Upgrade styles after adding missing class name from Baza as part of future upgrade
.confirm-modal .ant-modal-body span {
    font-size: @font-size-lg;
    color: @normal-color;
    margin-bottom: 30px;

    &:last-child,
    p:last-child {
        margin-bottom: 0;
    }
}
