// service-page image
.service-page__img {
    margin-bottom: 20px;
}

// service-page content
.service-page__content {
    @media (min-width: @screen-lg) {
        font-size: @font-size-lg;
    }
    > p,
    > ul {
        @media (min-width: @screen-lg) {
            margin-bottom: 32px;
        }
    }
}
