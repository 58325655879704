// color-muted
.color-muted {
    color: @normal-color;
}

// color-warning
.color-warning {
    color: @warning-color;
}

// color-error
.color-error {
    color: @error-color;
}

// color-success
.color-success {
    color: @success-color;
}

// color-primary
.color-primary {
    color: @primary-color;
}
