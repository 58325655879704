// info-list
.info-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

// info-list
.info-list {
    .purchase-methods & {
        @media (min-width: @screen-md) {
            font-size: @font-size-lg;
        }
    }
}

// info-list item
.info-list__item {
    margin-bottom: 5px;

    a {
        display: inline-block;
    }
}

// info-list label
.info-list__label {
    display: inline;
    margin-right: 5px;
}

// info-list label
.info-list__label {
    .personal & {
        font-weight: 700;
    }
}

// info-list text
.info-list__text {
    display: inline;
}

// info-list subtitle
.info-list + .payment-subtitle {
    clear: left;
    font-size: @font-size-sm;
}
