// payment-header
.payment-header {
    .layer(46px, 25px, auto, 0);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media (min-width: @screen-md) {
        left: 10px;
        right: 40px;
    }
}

// payment-header-icon
.payment-header-icon {
    line-height: 1;
    vertical-align: middle;

    &:before {
        transform: none;
    }
}
