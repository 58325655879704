// ant-picker
.ant-picker {
    width: 100%;
    padding: 14px 16px;
}

// ant-picker-suffix
.ant-picker-suffix {
    font-size: 22px;
}

// ant-picker-clear
.ant-picker-clear {
    display: flex;
    justify-content: center;
    font-size: 20px;
    width: 22px;
    height: 22px;
}

// ant-picker-cell-inner
.ant-picker-cell-inner {
    .ant-picker-cell & {
        & {
            border-width: 1px;
            border-radius: @border-radius-sm;
        }
    }
    .ant-picker-cell-in-view.ant-picker-cell-today & {
        &:before {
            border-width: 1px;
            border-radius: @border-radius-sm;
        }
    }
}
