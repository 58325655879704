// ant-input-prefix
.ant-input-prefix,
.ant-input-suffix {
    color: @normal-color;
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
        text-decoration: none;
    }
}

// ant-input-suffix-icon
.ant-input-suffix {
    .icon {
        font-size: 22px;
        color: @text-color;
    }
}
