//card-wrapper
.card-wrapper {
    box-shadow: 0 4px 36px 0 rgba(@black, 0.05);
    width: 100%;
    height: 420px;
    display: inline-flex;
    text-decoration: none;
    position: relative;
    border-radius: 20px;

    &:hover {
        box-shadow: 0 4px 36px 0 rgba(@black, 0.15);
        border-color: @neutral-6;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.card {
    flex-grow: 1;
    width: 100%;
    position: relative;
    padding: @padding-md;
    overflow: hidden;
}

.ant-card-hoverable:hover {
    box-shadow: none;
}

.ant-card-body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    color: @text-color;
    padding: 24px 20px;

    @media (min-width: @screen-md) {
        padding: 16px 0 0 0;
    }
}

.ant-card-cover {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        .layer(auto, auto, 0, auto);
        display: block;
        width: 100%;
        height: 122px;
        opacity: 0.5;
    }

    & img {
        border-radius: 14px;
    }
}

.ant-card-cover__abbreviation {
    position: absolute;
    bottom: 0;
    padding: 1.2rem 2.8rem;
    color: @white;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-bottom: 0;

    @media (max-width: @screen-sm-max) {
        padding: 1.2rem 2rem;
    }
}

.card-cover {
    height: 212px;
    position: relative;
    margin-bottom: 62px;
}

.card-cover__favorite {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 3.2rem;
}

.card-cover-item() {
    display: inline-flex;
    width: auto;
    height: 2.8rem;
    padding: 0.8rem 1.2rem;
    border-radius: 4px;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.card-cover__left-block {
    z-index: 1;
    position: absolute;
    display: inline-block;
    top: 20px;
    left: 20px;
}

.card-cover__new {
    .card-cover-item();
    margin-right: 8px;
    background-color: @white;
    color: @primary-color;
}

.card-cover__hot {
    .card-cover-item();
    background-color: #ffc900;
    color: @text-color;
}

.card-cover__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
