// personal title
.personal__title {
    &:extend(h3);
    margin: 0 0 20px;
}

// personal icon
.personal__icon {
    font-size: @font-size-lg;
    color: @neutral-7;
    margin-left: 5px;

    &:hover,
    &:active {
        color: darken(@neutral-7, 15%);
    }
}
