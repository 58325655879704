// ant-modal
.ant-modal {
    top: 0;
    padding-bottom: 0;
    min-width: 360px;

    @media (min-width: @screen-md) {
        min-width: 560px;
    }
}

// ant-modal-wrap
.ant-modal-wrap {
    min-width: 375px;

    @media (min-width: @screen-md) {
        display: flex;
        flex-direction: column;
    }
    &:before,
    &:after {
        @media (min-width: @screen-md) {
            content: '';
            flex-shrink: 0;
            flex-grow: 1;
            height: 30px;
        }
    }
}

// ant-modal-header
.ant-modal-header {
    @media (min-width: @screen-md) {
        padding-left: 45px;
        padding-right: 45px;
    }
}

// ant-modal-title
.ant-modal-title {
    font-weight: 700;
    padding-right: 30px;
}

// ant-modal-close
.ant-modal-close {
    top: 48px;
    right: 25px;

    @media (min-width: @screen-md) {
        right: 40px;
    }
}

// ant-modal-close-x
.ant-modal-close-x {
    transition: color 0.3s ease-in-out;
    color: @modal-close-color;

    &:hover,
    &:active {
        color: @text-color;
    }
}

// modal-close-icon overwrite
.ant-modal-close-icon,
.anticon-close {
    &:extend(.icon);

    > svg {
        display: none;
    }
    &:before {
        &:extend(.icon-close:before);
        display: inline-block;
    }
}

// ant-modal-body
.ant-modal-body {
    font-size: @font-size-sm;

    @media (min-width: @screen-md) {
        font-size: @font-size-base;
        padding: 0 45px;
    }
    &:last-child {
        padding-bottom: 50px;
    }
}

// ant-modal-footer
.ant-modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding-top: 30px;

    @media (min-width: @screen-xs) {
        flex-direction: row;
        justify-content: flex-end;
    }
    @media (min-width: @screen-md) {
        padding-left: 45px;
        padding-right: 45px;
    }
    > .ant-btn {
        & + .ant-btn,
        & + .ant-btn:not(.ant-dropdown-trigger) {
            margin: 15px 0 0 0;

            @media (min-width: @screen-xs) {
                margin: 0 0 0 15px;
            }
        }
    }
}

// ant-modal description
.ant-modal__descr {
    font-size: @font-size-lg;
    color: @normal-color;
    margin-bottom: 30px;

    &:last-child,
    p:last-child {
        margin-bottom: 0;
    }
}

// modal-footer
.modal-footer {
    display: flex;
    justify-content: flex-end;
}
