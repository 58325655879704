// payment-subtitle
.payment-subtitle {
    font-weight: 400;
    margin-top: 12px;
}

// payment-subtitle-not-cta
.payment-subtitle {
    .payment-btn-buy & {
        &:not(.payment-subtitle-cta) {
            margin-top: 4px;
        }
    }
}

// payment-subtitle-not-hint
.payment-subtitle {
    .payment-btn-item &,
    .payment-item-section & {
        &:not(.payment-subtitle-hint) {
            font-size: @font-size-base;

            @media (min-width: @screen-lg) {
                font-size: @font-size-lg;
            }
        }
    }
}

// payment-subtitle-section
.payment-subtitle {
    .payment-section__title & {
        margin-top: 4px;
    }
}

// payment-subtitle-btn-list
.payment-subtitle {
    .payment-btn-list & {
        margin-top: 8px;
    }
}

// payment-subtitle-details-item
.payment-subtitle {
    .payment-item-details__item & {
        display: inline;
    }
}

// payment-subtitle link
a.payment-subtitle__link {
    display: inline-block;
    text-decoration: underline;
    margin-top: 4px;
}

.payment-subtitle-error {
    color: @error-color;
}
