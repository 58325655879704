// ant-menu-item-dark
.ant-menu-item {
    .ant-menu-dark:not(.ant-menu-submenu) & {
        a {
            &:hover,
            &:active,
            &:focus {
                color: @menu-dark-color;

                @media (min-width: @screen-lg) {
                    border-bottom: solid 5px @primary-color;
                    color: @primary-color;
                }
            }
        }
    }
}

// ant-menu-item-selected-dark
.ant-menu-item-selected {
    .ant-menu-dark:not(.ant-menu-submenu) & {
        a {
            color: @menu-dark-color;

            @media (min-width: @screen-lg) {
                border-bottom: solid 5px @primary-color;
            }
        }
    }
}
