// item-buy
.item-buy {
    @media (min-width: @screen-md) {
        .button-size(@btn-height-base; @btn-padding-horizontal-base; @btn-font-size-lg; @btn-border-radius-base);
    }
    @media (min-width: @screen-lg) {
        padding-left: 18px;
        padding-right: 18px;
        margin-top: 12px;
    }
    .icon {
        @media (min-width: @screen-md) {
            font-size: 20px;
        }
    }
}
