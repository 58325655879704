// text-image
.text-image {
    margin-bottom: 75px;

    @media (min-width: @screen-lg) {
        margin-bottom: 100px;
    }
}

// text-image subtitle
.text-image__subtitle {
    &:extend(h6);
    color: @primary-color;
    text-transform: uppercase;
}

// text-image heading
.text-image__heading {
    &:extend(h1);
}

// text-image description
.text-image__descr {
    @media (min-width: @screen-lg) {
        font-size: 20px;
    }
    > p:last-of-type {
        margin: 0;
    }
}

// text-image column
.text-image__col {
    margin-bottom: 30px;

    @media (min-width: @screen-md) {
        .flex-col-width(6);
        margin-bottom: 0;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    @media (min-width: @screen-xl) {
        .flex-col-width(5);
        margin-right: percentage((1 / @grid-columns));
    }
}

// text-image col-txt-right
.text-image__col-text {
    .text-image-right & {
        @media (min-width: @screen-md) {
            order: 2;
        }
        @media (min-width: @screen-xl) {
            margin: 0 0 0 percentage((1 / @grid-columns));
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}

// txt-img col-image
.text-image__col-img {
    .text-image & {
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        background-position: center;
        height: 220px;

        @media (min-width: @screen-md) {
            .flex-col-width(6);
            margin-right: 0;
            height: auto;
        }
    }
}
