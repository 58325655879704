// purchase heading
.purchase__heading {
    &:extend(h2);
}

// purchase subheading
.purchase__subheading {
    &:extend(h3);
    margin: 40px 0 16px;

    @media (min-width: @screen-md) {
        margin: 52px 0 24px;
    }
}

// purchase info
.purchase__info {
    @media (min-width: @screen-lg) {
        .flex-col-width(8);
    }
    &:last-child {
        @media (min-width: @screen-sm) {
            .flex-col-width(12);
        }
    }
}

// purchase info-purchase-methods
.purchase__info {
    .purchase-methods & {
        @media (min-width: @screen-lg) {
            .flex-col-width(8);
        }
    }
}

// purchase row-add
.purchase__row-add {
    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
    & + .purchase__row:not(&) {
        margin-top: -24px;
    }
}

// purchase summary
.purchase__summary {
    @media (min-width: @screen-lg) {
        .flex-col-width(4);
    }
}

// purchase description
.purchase__descr {
    margin-bottom: 20px;

    @media (min-width: @screen-md) {
        font-size: @font-size-lg;
    }
    a {
        color: @normal-color;

        &:hover,
        &:active {
            color: @primary-color;
            text-decoration: underline;
        }
    }
}

// purchase description-agreement
.purchase__descr {
    .purchase-agrmt & {
        margin-bottom: 40px;
    }
}

// purchase description-faded
.purchase__descr-faded {
    color: @normal-color;

    a {
        color: @normal-color;

        &:hover,
        &:active {
            color: @primary-color;
            text-decoration: underline;
        }
    }
}

// purchase description-faded-agreement & details
.purchase__descr-faded {
    .purchase-agrmt &,
    .purchase-details & {
        color: initial;
    }
}

// purchase divider
.purchase__divider {
    margin: 20px 0;
}

// purchase alert
.purchase__alert {
    margin-bottom: 20px;
}
