// ant-input-group-wrapper
.ant-input-group-wrapper {
    display: block;
    width: auto;
    margin: 0 -8px;
}

// ant-input-group
.ant-input-group {
    border-spacing: 8px 0;
}

// ant-input-group-addon
.ant-input-group-addon {
    background: @white;

    &:first-child,
    &:last-child {
        border: 2px solid @input-border-color;
        border-radius: @border-radius-base;
    }
}

// ant-input-group-input
.ant-input {
    &:first-child,
    &:last-child {
        .ant-input-group > & {
            border-radius: @border-radius-base;
        }
    }
}

// ant-input-group-select
.ant-select {
    .ant-input-group-addon & {
        margin-left: -18px;
        margin-right: -18px;
        width: auto;
    }
    .ant-input-group-addon:first-child & {
        .ant-select-selector {
            border-radius: @border-radius-base;
        }
    }
}
