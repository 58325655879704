// container
.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: (@gutter / 2);
    padding-left: (@gutter / 2);

    @media (min-width: @screen-sm) {
        width: @container-max-width-sm;
        max-width: 100%;
    }
    @media (min-width: @screen-md) {
        width: @container-max-width-md;
        max-width: 100%;
    }
    @media (min-width: @screen-lg) {
        width: @container-max-width-lg;
        max-width: 100%;
    }
    @media (min-width: @screen-xl) {
        width: @container-max-width-xl;
        max-width: 100%;
    }
}

// container-fluid
.container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: (@gutter / 2);
    padding-left: (@gutter / 2);
}
